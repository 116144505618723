import React from 'react';

import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard-container">
      
      <div className="admin-dashboard-content">
        <h1>مرحبا بك في لوحة تحكم وليد بوحوش</h1>
        
          
       
      </div>
    </div>
  );
};

export default AdminDashboard;
