import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./PeriodDetails.css";

const PeriodDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getGoogleDriveImageLink = (urlPic) => {
    const fileIdMatch = urlPic?.match(/\/file\/d\/([a-zA-Z0-9_-]+)/);
    if (fileIdMatch && fileIdMatch[1]) {
      return `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}&sz=w1000`;
    }
    return urlPic;
  };

  useEffect(() => {
    const fetchLessons = async (classe, matiereNom, periodeNom) => {
      try {
        const response = await axios.get(
          `https://api-dev.walidacademy.net/admin/lesson/list?classe=${classe}&matiere=${matiereNom}&periode=${periodeNom}`
        );
        setLessons(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching lessons:", err.response?.data || err.message);
        setError("فشل في جلب الدروس.");
        setLoading(false);
      }
    };

    if (state?.grade && state?.subjectName && state?.periodName) {
      fetchLessons(state.grade, state.subjectName, state.periodName);
    } else {
      setError("لم يتم تقديم البيانات اللازمة لجلب الدروس.");
      setLoading(false);
    }
  }, [state?.grade, state?.subjectName, state?.periodName]);

  const handleLessonClick = (lesson,index) => {
    navigate(`/lesson/${index}`, {
      state: { lesson },
    });
  };

  if (loading) {
    return <div>Loading lessons...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (lessons.length === 0) {
    return <div style={{ marginTop: 300, marginLeft: 100 }}>No lessons available for this period.</div>;
  }

  return (
    <div>
      <div className="period-details">
        <h1>{state?.periodName || "Period Details"}</h1>
        <div className="lessons">
          {lessons.map((lesson,index) => (
            <div
              key={lesson.id}
              className="lesson-card"
              onClick={() => handleLessonClick(lesson,index)}
            >
              <img src={getGoogleDriveImageLink(lesson.LienImage)} alt={lesson.Nom} />
              <h2>{lesson.Nom}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PeriodDetails;
