import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import "./SubjectDetails.css";

const SubjectDetails = () => {
  const { state } = useLocation(); // Access passed state (grade, subjectName)
  const navigate = useNavigate();
  const [periods, setPeriods] = useState([]); // State to store periods
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Helper function to convert Google Drive link to direct thumbnail link
  const getGoogleDriveImageLink = (urlPic) => {
    if (typeof urlPic === "string") { // Ensure urlPic is a string
      const fileIdMatch = urlPic.match(/\/file\/d\/([a-zA-Z0-9_-]+)/); // Extract the file ID from the URL
      if (fileIdMatch && fileIdMatch[1]) {
        return `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}&sz=w1000`; // Convert to a direct thumbnail link
      }
    }
    console.log("aaa",urlPic)
    return urlPic || ""; // If not a Google Drive link or invalid, return the original URL or an empty string
  };
  

  // Fetch periods from the backend
  useEffect(() => {
    const fetchPeriods = async (classe, matiereNom) => {
      try {
        const response = await axios.get(
          `https://api-dev.walidacademy.net/admin/period/list?classe=${classe}&matiere=${matiereNom}`
        );
        setPeriods(response.data); // Store periods in state
        setLoading(false); // Turn off loading state
      } catch (err) {
        console.error("Error fetching periods:", err.response?.data || err.message);
        setError("فشل في جلب الفترات.");
        setLoading(false); // Turn off loading even in case of error
      }
    };

    if (state?.grade && state?.subjectName) {
      fetchPeriods(state.grade, state.subjectName); // Pass grade and subject name
    } else {
      setError("لم يتم تقديم البيانات اللازمة لجلب الفترات.");
      setLoading(false);
    }
  }, [state?.grade, state?.subjectName]); // Dependency array includes grade and subjectName

  // Handle period click to navigate to the period details page
  const handlePeriodClick = (index) => {
    const selectedPeriod = periods[index]; // Get the specific period based on the index
    navigate(`/period/${index}`, {
      state: {
        grade: state?.grade,
        subjectName: state?.subjectName,
        periodName: selectedPeriod?.Nom, // Include the period name
      },
    });
  };

  if (loading) {
    return <div>Loading periods...</div>; // Show loading message while data is being fetched
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message if request fails
  }
  
  return (
    <div>
      <div className="subject-details">
        <h1>{state?.subjectName || "Subject Details"}</h1> {/* Display subject name */}
        <div className="periods">
          {periods.map((period, index) => {
            const directImageUrl = getGoogleDriveImageLink(period.LienImage);
            return (
              <div key={index} className="period-card" onClick={() => handlePeriodClick(index)}>
                <img src={directImageUrl} alt={`${period.Nom}`} />
                <h2>{period.Nom}</h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubjectDetails;
