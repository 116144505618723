import React from 'react';
import './LessonDetails.css';
import { useLocation } from 'react-router-dom';

const LessonDetail = ({ user }) => {
  const { state } = useLocation(); // Access the lesson data passed via navigation
  const lesson = state?.lesson; // Extract the lesson data

  // Function to extract the Google Drive embed link for files
  const getGoogleDriveEmbedUrl = (link) => {
    if (link) {
      const fileId = link.split('/d/')[1]?.split('/')[0];
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }
    return '';
  };

  if (!lesson) {
    return <div>لا توجد بيانات عن الدرس المعروض</div>;
  }
console.log("acces:",user)
  return (
    <div className="lesson-detail-container">
      <h1>{lesson.title}</h1>

      {/* Video Section */}
      {user?.access  ? (
        <div className="lesson-video">
          <iframe
            title="lesson-video"
            src={getGoogleDriveEmbedUrl(lesson.urlVideo)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            width="640"
            height="480"
          ></iframe>
        </div>
      ) : (
        !user?.access && <p>يجب عليك الاشتراك لتتمكن من المشاهدة</p>
      )}

      {/* PowerPoint Section */}
      {user?.access && lesson.urlPowerPoint ? (
        <div className="lesson-powerpoint">
          <iframe
            title="lesson-presentation"
            src={getGoogleDriveEmbedUrl(lesson.urlPowerPoint)}
            frameBorder="0"
            allowFullScreen
            width="640"
            height="480"
            onError={(e) => {
              e.target.style.display = 'none'; // Hide iframe if loading fails
            }}
          ></iframe>
        </div>
      ) : null}
    </div>
  );
};

export default LessonDetail;
