import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminSubjects = () => {
  const [classes, setClasses] = useState([]);
  const [matieres, setMatieres] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedMatiere, setSelectedMatiere] = useState("");
  const [selectedPeriode, setSelectedPeriode] = useState("");
  const [newClassName, setNewClassName] = useState("");
  const [newMatiereName, setNewMatiereName] = useState("");
  const [newMatiereImage, setNewMatiereImage] = useState("");
  const [newPeriodName, setNewPeriodName] = useState("");
  const [newPeriodImage, setNewPeriodImage] = useState("");
  const [newLessonName, setNewLessonName] = useState("");
  const [newLessonImage, setNewLessonImage] = useState("");
  const [newLessonVideo, setNewLessonVideo] = useState([]);
  const [newLessonDiapo, setNewLessonDiapo] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
// Reset messages
const resetMessages = () => {
  setError("");
  setSuccess("");
};
  const fetchClasses = async () => {
    try {
      const response = await axios.get("https://api-dev.walidacademy.net/admin/class/list");
      setClasses(response.data);
    } catch (err) {
      console.error("Error fetching classes:", err);
      setError("فشل في جلب الأقسام.");
    }
  };

  const fetchMatieres = async (classe) => {
    try {
      const encodedClass = encodeURIComponent(classe);
      const response = await axios.get(`https://api-dev.walidacademy.net/admin/matiere/list?classe=${encodedClass}`);
      setMatieres(response.data);
    } catch (err) {
      console.error("Error fetching matieres:", err);
      setError("فشل في جلب المواد.");
    }
  };
  console.log("aaaa",lessons)

  const fetchPeriods = async (classe, matiereNom) => {
    try {
      const response = await axios.get(
        `https://api-dev.walidacademy.net/admin/period/list?classe=${classe}&matiere=${matiereNom}`
      );
      setPeriods(response.data);
    } catch (err) {
      console.error("Error fetching periods:", err.response?.data || err.message);
      setError("فشل في جلب الفترات.");
    }
  };

  const fetchLessons = async (classe, matiereNom, periodeNom) => {
    try {
      const response = await axios.get(
        `https://api-dev.walidacademy.net/admin/lesson/list?classe=${classe}&matiere=${matiereNom}&periode=${periodeNom}`
      );
      setLessons(response.data);
    } catch (err) {
      console.error("Error fetching lessons:", err.response?.data || err.message);
      setError("فشل في جلب الدروس.");
    }
  };

  const handleAddClass = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      
      await axios.post(
        "https://api-dev.walidacademy.net/admin/class",
        { Nom: newClassName },
        { headers: { "Content-Type": "application/json" } }
      );
      setSuccess("تمت إضافة القسم بنجاح!");
      setNewClassName("");
      fetchClasses();
    } catch (err) {
      console.error("Error adding class:", err);
      setError("فشل في إضافة القسم. حاول مرة أخرى.");
    }
  };

  const handleAddMatiere = async () => {
    setError("");
    setSuccess("");

    if (!newMatiereName || !newMatiereImage || !selectedClass) {
      setError("يرجى ملء جميع الحقول.");
      return;
    }

    try {
      await axios.post(
        "https://api-dev.walidacademy.net/admin/matiere",
        {
          classe: selectedClass,
          matiere: newMatiereName,
          lien_image: newMatiereImage,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      setSuccess("تمت إضافة المادة بنجاح!");
      setNewMatiereName("");
      setNewMatiereImage("");
      fetchMatieres(selectedClass);
    } catch (err) {
      console.error("Error adding matiere:", err);
      setError("فشل في إضافة المادة. حاول مرة أخرى.");
    }
  };

  const handleAddPeriod = async () => {
    setError("");
    setSuccess("");

    if (!selectedMatiere || !newPeriodName || !newPeriodImage || !selectedClass) {
      setError("يرجى ملء جميع الحقول.");
      return;
    }

    try {
      await axios.post(
        "https://api-dev.walidacademy.net/admin/period",
        {
          classe: selectedClass,
          matiere: selectedMatiere.Nom,
          periode: {
            nom: newPeriodName,
            lien_image: newPeriodImage,
          },
        },
        { headers: { "Content-Type": "application/json" } }
      );

      setSuccess("تمت إضافة الفترة بنجاح!");
      setNewPeriodName("");
      setNewPeriodImage("");
      fetchPeriods(selectedClass, selectedMatiere.Nom);
    } catch (err) {
      console.error("Error adding period:", err.response?.data || err.message);
      setError("فشل في إضافة الفترة. حاول مرة أخرى.");
    }
  };

  const handleAddLesson = async () => {
    setError("");
    setSuccess("");

    if (!selectedPeriode || !newLessonName || !selectedClass || !selectedMatiere) {
      setError("يرجى ملء جميع الحقول.");
      return;
    }

    try {
      await axios.post(
        "https://api-dev.walidacademy.net/admin/lesson",
        {
          classe: selectedClass,
          matiere: selectedMatiere.Nom,
          periode: selectedPeriode.Nom,
        
            lesson: newLessonName,
            lien_image: newLessonImage,
            lien_video: newLessonVideo,
            lien_diapo: newLessonDiapo,
          
        },
        { headers: { "Content-Type": "application/json" } }
      );

      setSuccess("تمت إضافة الدرس بنجاح!");
      setNewLessonName("");
      setNewLessonImage("");
      setNewLessonVideo([]);
      setNewLessonDiapo("");
      fetchLessons(selectedClass, selectedMatiere.Nom, selectedPeriode.Nom);
    } catch (err) {
      console.error("Error adding lesson:", err.response?.data || err.message);
      setError("فشل في إضافة الدرس. حاول مرة أخرى.");
    }
  };

  const handleClassClick = (classe) => {
    setSelectedClass(classe);
    setMatieres([]);
    setPeriods([]);
    setLessons([]);
    fetchMatieres(classe);
  };

 

  const handlePeriodeClick = (periode) => {
    if (!periode || !periode.Nom) {
      setError("يرجى اختيار فترة صالحة.");
      return;
    }
    setSelectedPeriode(periode);
    fetchLessons(selectedClass, selectedMatiere.Nom, periode.Nom);
  };
  // Delete a class
  const handleDeleteClass = async (className) => {
    resetMessages();

    try {
      await axios.delete(
        `https://api-dev.walidacademy.net/admin/class`,
        {
          headers: { "Content-Type": "application/json" },
          data: { Nom: className }, // Passing `Nom` in the request body
        }
      );
      setSuccess(`تم حذف القسم "${className}" بنجاح!`);
      fetchClasses(); // Refresh the list of classes
    } catch (err) {
      console.error("Error deleting class:", err.response || err);
      setError("فشل في حذف القسم. حاول مرة أخرى.");
    }
  };
// Delete a class (Subject)
const handleDeleteSubject = async (matiere) => {
  resetMessages();

  try {
    // Perform delete request
    await axios.delete(`https://api-dev.walidacademy.net/admin/matiere`, {
      headers: { "Content-Type": "application/json" },
      data: {
        classe: selectedClass,
        matiere: matiere.Nom,
      },
    });

    // Update the matieres state to remove the deleted matiere
    setMatieres((prevMatieres) =>
      prevMatieres.filter((item) => item.Nom !== matiere.Nom)
    );

    setSuccess(`تم حذف المادة "${matiere.Nom}" بنجاح!`);
  } catch (err) {
    console.error("Error deleting subject:", err.response || err);
    setError("فشل في حذف المادة. حاول مرة أخرى.");
  }
};


const handleDeletePeriode = async (periode) => {
  resetMessages();

  console.log("Selected Periode:", periode);
  console.log("Selected Matiere:", selectedMatiere);

  try {
    await axios.delete(`https://api-dev.walidacademy.net/admin/period`, {
      headers: { "Content-Type": "application/json" },
      data: {
        classe: selectedClass,
        matiere: selectedMatiere.Nom,
        periode: periode.Nom,
      },
    });

    // Update the periods state to remove the deleted period
    setPeriods((prevPeriods) =>
      prevPeriods.filter((item) => item.Nom !== periode.Nom)
    );

    setSuccess(`تم حذف الفترة "${periode.Nom}" بنجاح!`);
  } catch (err) {
    console.error("Error deleting period:", err.response || err);
    setError("فشل في حذف الفترة. حاول مرة أخرى.");
  }
};

const handleDeleteLesson = async (lesson) => {
  resetMessages();

  console.log("Selected Class:", selectedClass);
  console.log("Selected Periode:", selectedPeriode);
  console.log("Selected Lesson:", lesson);

  try {
    await axios.delete(`https://api-dev.walidacademy.net/admin/lesson`, {
      headers: { "Content-Type": "application/json" },
      data: {
        classe: selectedClass,
        matiere: selectedMatiere.Nom,
        periode: selectedPeriode.Nom,
        lesson: lesson.Nom,
      },
    });

    // Update the lessons state to remove the deleted lesson
    setLessons((prevLessons) =>
      prevLessons.filter((item) => item.Nom !== lesson.Nom)
    );

    setSuccess(`تم حذف الدرس "${lesson.Nom}" بنجاح!`);
  } catch (err) {
    console.error("Error deleting lesson:", err.response || err);
    setError("فشل في حذف الدرس. حاول مرة أخرى.");
  }
};


// Handle Matiere Click (Subject selection)
const handleMatiereClick = (matiere) => {
  // Log the matiere being selected
  
  console.log("Selected Matiere:", matiere);

  // Check if matiere and matiere.Nom exist
  if (!matiere || !matiere.Nom) {
      setError("يرجى اختيار مادة صالحة.");
      console.log("Invalid Matiere: No valid Nom property found.");
      return;
  }

  // Set the selected matiere and fetch periods
  setSelectedMatiere(matiere);
  fetchPeriods(selectedClass, matiere.Nom);

  // Log selected matiere and periods fetching attempt
  console.log("Fetching periods for Class:", selectedClass);
  console.log("Matiere Nom:", matiere.Nom);
};



  useEffect(() => {
    fetchClasses();
  }, []);




  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto", marginTop: "40px" }}>
      <h2>إدارة الأقسام والمواد</h2>
  
      {/* Add new class */}
      <div style={{ marginTop: "20px" }}>
        <h3>إضافة قسم جديد</h3>
        <input
          type="text"
          value={newClassName}
          onChange={(e) => setNewClassName(e.target.value)}
          placeholder="اسم القسم"
          style={{
            width: "100%",
            padding: "10px",
            margin: "5px 0",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <button
          onClick={handleAddClass}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          إضافة القسم
        </button>
      </div>
  
      {/* List of classes */}
      <div style={{ marginTop: "20px" }}>
        <h3>قائمة الأقسام</h3>
        {classes.length > 0 ? (
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {classes.map((cls, index) => (
              <li
                key={index}
                style={{
                  padding: "10px",
                  margin: "5px 0",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#f9f9f9",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                onClick={() => handleClassClick(cls)}
              >

                {cls}
               
                <button
                  onClick={() => handleDeleteClass(cls)}
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#ff4d4d",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  حذف
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>لا توجد أقسام متاحة.</p>
        )}
      </div>
  
      {/* Add new subject */}
      {selectedClass && (
        <div style={{ marginTop: "20px" }}>
          <h3>إضافة مادة للقسم: {selectedClass}</h3>
          <input
            type="text"
            value={newMatiereName}
            onChange={(e) => setNewMatiereName(e.target.value)}
            placeholder="اسم المادة"
            style={{
              width: "100%",
              padding: "10px",
              margin: "5px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <input
            type="text"
            value={newMatiereImage}
            onChange={(e) => setNewMatiereImage(e.target.value)}
            placeholder="رابط الصورة"
            style={{
              width: "100%",
              padding: "10px",
              margin: "5px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <button
            onClick={handleAddMatiere}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            إضافة المادة
          </button>
        </div>
      )}
  
     {/* Display subjects for the selected class */}
{selectedClass && (
  <div style={{ marginTop: "20px" }}>
    <h3>المواد للقسم: {selectedClass}</h3>
    {matieres.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {matieres.map((matiere, index) => (
          <li
            key={index}
            style={{
              padding: "10px",
              margin: "5px 0",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f1f1f1",
              fontSize: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div onClick={() => handleMatiereClick(matiere)} style={{ cursor: "pointer" }}>
              <strong>الاسم:</strong> {matiere.Nom}
              <br />
              <strong>الصورة:</strong>{" "}
              <a href={matiere.LienImage} target="_blank" rel="noreferrer">
                {matiere.LienImage || "لا توجد صورة"}
              </a>
            </div>
            <button
         onClick={() => handleDeleteSubject(matiere)}  // Pass the matiere object here
         style={{
        padding: "5px 10px",
        backgroundColor: "#ff4d4d",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    }}
      >
    حذف
</button>

          </li>
        ))}
      </ul>
    ) : (
      <p>لا توجد مواد لهذا القسم.</p>
    )}
  </div>
)}

  
      {/* Add new period */}
      {selectedClass && selectedMatiere && (
  <div style={{ marginTop: "20px" }}>
    <h3>إضافة فترة للمادة: {selectedMatiere.Nom}</h3>
    {/* Input for period name */}
    <input
      type="text"
      value={newPeriodName}
      onChange={(e) => setNewPeriodName(e.target.value)}
      placeholder="اسم الفترة"
      style={{
        width: "100%",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
    {/* Input for period image link */}
    <input
      type="text"
      value={newPeriodImage}
      onChange={(e) => setNewPeriodImage(e.target.value)}
      placeholder="رابط الصورة"
      style={{
        width: "100%",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
    {/* Button to add the period */}
    <button
      onClick={handleAddPeriod}
      style={{
        padding: "10px 20px",
        backgroundColor: "#28a745",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      إضافة الفترة
    </button>
  </div>
)}

{/* Display periods for the selected subject */}
{selectedClass && selectedMatiere && (
  <div style={{ marginTop: "20px" }}>
    <h3>الفترات للمادة: {selectedMatiere.Nom}</h3>
    {periods.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {periods.map((periode, index) => (
          <><li
            key={index}
            style={{
              padding: "10px",
              margin: "5px 0",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#e9ecef",
              fontSize: "16px",
            }}
            onClick={() => handlePeriodeClick(periode)}
          >
            <strong>الاسم:</strong> {periode.Nom}
            <br />
            <strong>الصورة:</strong>{" "}
            <a href={periode.LienImage} target="_blank" rel="noreferrer">
              {periode.LienImage || "لا توجد صورة"}
            </a>
          </li><button
            onClick={() => handleDeletePeriode(periode)} // Pass the matiere object here
            style={{
              padding: "5px 10px",
              backgroundColor: "#ff4d4d",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
              حذف
            </button></>
        ))}
      </ul>
    ) : (
      <p>لا توجد فترات لهذه المادة.</p>
    )}
  </div>
)}

{selectedClass && selectedMatiere && selectedPeriode && (
  <div style={{ marginTop: "20px" }}>
    <h3>إضافة درس للفترة: {selectedPeriode.Nom}</h3>
    <input
      type="text"
      value={newLessonName}
      onChange={(e) => setNewLessonName(e.target.value)}
      placeholder="اسم الدرس"
      style={{
        width: "100%",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
    <input
      type="text"
      value={newLessonImage}
      onChange={(e) => setNewLessonImage(e.target.value)}
      placeholder="رابط الصورة"
      style={{
        width: "100%",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />

    {/* Multiple video handling */}
    <div>
      <label htmlFor="videos">رابط الفيديوهات (أضف أكثر من رابط مفصول بفواصل):</label>
      <textarea
        id="videos"
        value={newLessonVideo} // Array of video URLs
        onChange={(e) => setNewLessonVideo(e.target.value.split(","))} // Split on commas
        placeholder="رابط الفيديو 1, رابط الفيديو 2, ..."
        style={{
          width: "100%",
          padding: "10px",
          margin: "5px 0",
          borderRadius: "5px",
          border: "1px solid #ccc",
          height: "100px", // Adjust height as needed
        }}
      />
    </div>

    <input
      type="text"
      value={newLessonDiapo}
      onChange={(e) => setNewLessonDiapo(e.target.value)}
      placeholder="رابط الديبو (اختياري)"
      style={{
        width: "100%",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
        border: "1px solid #ccc",
      }}
    />
    <button
      onClick={handleAddLesson}
      style={{
        padding: "10px 20px",
        backgroundColor: "#ffc107",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      إضافة الدرس
    </button>
  </div>
)}
{selectedClass && selectedMatiere && selectedPeriode && (
  <div style={{ marginTop: "20px" }}>
    <h3>الدروس للفترة: {selectedPeriode.Nom}</h3>
    { lessons.length > 0 ? (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {lessons.map((lesson, index) => (
          <><li
            key={index}
            style={{
              padding: "10px",
              margin: "5px 0",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#e0e0e0",
              fontSize: "16px",
            }}
          >
            <strong>الاسم:</strong> {lesson.Nom}
            <br />
            <strong>رابط الفيديو:</strong>{" "}
            {lesson.LiensVideos && lesson.LiensVideos.length > 0 ? (
              <ul>
                {lesson.LiensVideos.map((video, i) => (
                  <li key={i}>
                    {video}
                  </li>
                ))}
              </ul>
            ) : (
              <p>لا يوجد رابط فيديو.</p>
            )}
            <br />
            <strong>رابط الديبو (اختياري):</strong>{" "}
            {lesson.LienDiapo && <a href={lesson.LienDiapo} target="_blank" rel="noreferrer">{lesson.LienDiapo}</a>}
          </li><button
            style={{
              marginTop: "10px",
              padding: "5px 10px",
              backgroundColor: "#ff4d4d",
              color: "white",
              border: "none",
              borderRadius: "3px",
              cursor: "pointer",
            }}
            onClick={() => handleDeleteLesson(lesson)}
          >
              حذف الدرس
            </button></>
          
        ))}
      </ul>
    ) : (
      <p>لا توجد دروس لهذه الفترة.</p>
    )}
  </div>
)}
  
      {/* Error and success messages */}
      {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
      {success && <p style={{ color: "green", marginTop: "10px" }}>{success}</p>}
    </div>
  );
  
};

export default AdminSubjects;
