import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import pic1 from "../../assets/2.jpg"; // Image for الرياضيات (Math in Arabic)
import pic2 from "../../assets/44.jpg"; // Image for other subjects
import "./Subjects.css"; // CSS for styling

const Subjects = ({ user }) => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([]); // State to store subjects
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchMatieres = async () => {
      if (!user?.grade) {
        setError("لم يتم تحديد الصف الدراسي.");
        return;
      }
      try {
        const encodedClass = encodeURIComponent(user.grade);
        const response = await axios.get(
          `https://api-dev.walidacademy.net/admin/matiere/list?classe=${encodedClass}`
        );
        setSubjects(response.data);
      } catch (err) {
        console.error("Error fetching subjects:", err);
        setError("فشل في جلب المواد.");
      }
    };

    fetchMatieres();
  }, [user?.grade]); // Dependency array includes user.grade to refetch if it changes

  const handleSubjectClick = (subjectName ,index) => {
    navigate(`/subject/${index}/periods`, { state: { grade: user.grade, subjectName } });
  };

  return (
    <div>
      <div className="subjects-container">
        <div className="subjects-content">
          <h2>المواد الدراسية</h2>
          <p>ابدأ الدراسة الآن واستفد من الدروس والتمارين التفاعلية.</p>
          {error && <p className="error">{error}</p>} {/* Display error if exists */}
          <div className="subjects">
            {subjects.map((subject, index) => (
              <div
                key={index} // Use index as the key
                className="subject-card"
                onClick={() => handleSubjectClick(subject.Nom , index)} // Pass subject name
              >
                <img
                  src={subject.Nom === "الرياضيات" ? pic1 : pic2} // Conditional image rendering based on subject name
                  alt={`Subject ${subject.Nom}`}
                />
                <h2>{subject.Nom}</h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subjects;
