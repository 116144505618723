import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css"; // Import the CSS file

const LoginForm = () => {
  const [form, setForm] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submission initiated");
    try {
      console.log("Sending login request...");
      const response = await axios.post(
        "https://api-dev.walidacademy.net/signin",
        {
          email: form.email,
          password: form.password,
        }
      );

      console.log("Login successful:", response);
      const tokenData = { accessToken: response.data, email: form.email };
      localStorage.setItem("token", JSON.stringify(tokenData));

      toast.success("تم تسجيل الدخول بنجاح! جارٍ التحويل...");
      
      // Navigate and refresh the page
      setTimeout(() => {
        navigate(`/welcome?email=${encodeURIComponent(form.email)}`);
        window.location.reload(); // Refresh the page
      }, 2000);
    } catch (error) {
      console.error("Login error:", error);
      const errorMessage = error.response?.data?.message || "فشل تسجيل الدخول. حاول مرة أخرى.";
      toast.error(errorMessage);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h1 className="login-title">تسجيل الدخول</h1>

        <div className="input-group">
          <label htmlFor="email" className="input-label">البريد الإلكتروني</label>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>

        <div className="input-group">
          <label htmlFor="password" className="input-label">كلمة السر</label>
          <input
            type="password"
            id="password"
            name="password"
            value={form.password}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>

        <button type="submit" className="submit-btn">تسجيل الدخول</button>

        <p className="register-link">
          ليس لديك حساب؟ <a href="/register" className="link">إنشاء حساب</a>
        </p>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default LoginForm;
