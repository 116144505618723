import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import './AdminUsers.css';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  // استرجاع قائمة المستخدمين من الخادم
  const fetchUsers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.get(`https://api-dev.walidacademy.net/admin/user/list?timestamp=${new Date().getTime()}`, {
        headers: { Authorization: `Bearer ${token.accessToken}` },
      });
      setUsers(response.data); // تعيين البيانات المسترجعة إلى حالة المستخدمين
    } catch (error) {
      setError('فشل في استرجاع المستخدمين. تأكد من أنك مفوض.');
      console.error('Error fetching users:', error);
    }
  };

  // استدعاء الدالة لاسترجاع المستخدمين عند تحميل الصفحة
  useEffect(() => {
    fetchUsers();
  }, []);

  // تغيير حالة الوصول للمستخدم
  const handleToggleAccess = async (userEmail, currentAccess) => {
    try {
      const token = JSON.parse(localStorage.getItem('token'));
      const newAccess = !currentAccess; // عكس حالة الوصول الحالية
      const response = await axios.put(
        'https://api-dev.walidacademy.net/admin/user/access',
        { email: userEmail, access: newAccess },
        { headers: { Authorization: `Bearer ${token.accessToken}` } }
      );

      console.log('Backend response:', response.data);

      // تحديث الحالة المحلية بعد التغيير
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.email === userEmail ? { ...user, access: newAccess } : user
        )
      );
    } catch (error) {
      setError('فشل في تحديث حالة الوصول.');
      console.error('Error updating access:', error);
    }
  };

  return (
    <div className="admin-users-container">
      <h1>إدارة المستخدمين</h1>
      {error && <p className="error-message">{error}</p>}
      <table className="users-table">
        <thead>
          <tr>
            <th>اللقب</th>
            <th>الإسم</th>
            <th>رقم الهاتف</th>
            <th>البريد الإلكتروني</th>
            <th>القسم</th>
            <th>الوصول للفيديوهات</th>
            <th>إجراءات</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.email}>
              <td>{user.surname}</td>
              <td>{user.name}</td>
              <td>{user.phone}</td>
              <td>{user.email}</td>
              <td>{user.grade}</td>
              <td>{user.access ? 'نعم' : 'لا'}</td>
              <td>
                <button
                  className={`access-button ${user.access ? 'revoke' : 'grant'}`}
                  onClick={() => handleToggleAccess(user.email, user.access)}
                >
                  {user.access ? <FiXCircle /> : <FiCheckCircle />}
                  {user.access ? 'إلغاء الوصول' : 'منح الوصول'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminUsers;
